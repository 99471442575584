import React from 'react'
import PropTypes from 'prop-types'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { SwiperSlide } from 'swiper/react'
import Slider from '../../atoms/slider'
import TeaserItem from './TeaserItem'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'
import BaseDotted from '../../atoms/dotted/BaseDotted'
import { DottedFadebarVariantA5mm, DottedFadebarVariantB5mm } from '../../atoms/dotted'
import HeadlineComponent from '../../atoms/headline'

const Teaser = ({
    teaserList,
    title,
    btnLinkText,
    btnVariant,
    btnColorDefault,
    btnColorHover,
    isDotted,
    bgcolor,
    isDarkmode,
    isSlider,
    className,
    tagHeadline,
    swiperColor,
}) => {
    const isMobile = useDeviceType() === 'mobile'
    const DARK_MODE_COLOR = 'var(--color-black-100)'
    const NORMAL_MODE_COLOR = 'var(--color-white-100)'

    const renderTeaserList = () => teaserList.map((item, index) => {
        const { name, reference, desc } = item
        const media = item.image.media

        if (isMobile) {
            return (
                <TeaserItem
                    key={name}
                    btnLinkText={btnLinkText}
                    btnVariant={btnVariant}
                    isDarkmode={isDarkmode}
                    btnColorDefault={btnColorDefault}
                    btnColorHover={btnColorHover}
                    content={{
                        media,
                        name,
                        reference,
                        desc,
                    }}
                />
            )
        }
        return (
            <SwiperSlide key={name}>
                <TeaserItem
                    btnLinkText={btnLinkText}
                    btnVariant={btnVariant}
                    isDarkmode={isDarkmode}
                    btnColorDefault={btnColorDefault}
                    btnColorHover={btnColorHover}
                    content={{
                        media,
                        name,
                        reference,
                        desc,
                    }}
                />
            </SwiperSlide>
        )
    })

    const renderTeasersByDevice = () => {
        if (isMobile) {
            return (
                <div className={'teaser-mobile'}>
                    {renderTeaserList()}
                </div>
            )
        }

        if (isSlider) {
            return (
                <Slider
                    swiperColor={swiperColor}
                    spaceBetween={24}
                    slidesPerView={4}
                    slidesPerGroup={4}
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                        },
                        1024: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                        },
                    }}
                >
                    {renderTeaserList()}
                </Slider>
            )
        }

        return (
            <div className={'unactive-slide'}>
                {renderTeaserList()}
            </div>
        )
    }

    const renderDottedBar = () => {
        if (!isDotted) {
            return null
        }

        const dottedColor = isDarkmode ? DARK_MODE_COLOR : bgcolor

        return (
            <div className={'teaser--dotted-bar'}>
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantA5mm fill={dottedColor} />}
                    height={14}
                    isTop
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantA5mm fill={dottedColor} />}
                    height={14}
                    isBottom
                    isRotate
                    from={'right'}
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isBottom
                    isRotate
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isTop
                    from={'right'}
                />
            </div>
        )
    }

    const classList = [
        (isDarkmode || bgcolor !== NORMAL_MODE_COLOR) ? 'relative py-comp' : 'my-comp',
        className,
        isDotted ? 'my-14px' : '',
    ]

    const style = {
        backgroundColor: isDarkmode ? DARK_MODE_COLOR : bgcolor,
    }

    return (
        <FullPageWidthWrapper
            customClasses={classList.join(' ')}
            style={style}
        >
            {renderDottedBar()}
            <div className={'o-wrapper'}>
                <div className={'o-grid o-region'}>
                    <div className={'o-cell o-cell--12'}>
                        <div className={`teaser${isDarkmode ? ' dark-mode' : ''}`}>
                            <HeadlineComponent
                                className={'text-center'}
                                text={title}
                                tagHeadline={tagHeadline}
                            />
                            {renderTeasersByDevice()}
                        </div>
                    </div>
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

Teaser.propTypes = {
    tagHeadline: PropTypes.string,
    title: PropTypes.string,
    btnLinkText: PropTypes.string,
    isDarkmode: PropTypes.bool,
    isSlider: PropTypes.bool,
    isDotted: PropTypes.bool,
    className: PropTypes.string,
    btnVariant: PropTypes.string,
    btnColorDefault: PropTypes.string,
    btnColorHover: PropTypes.string,
    bgcolor: PropTypes.string,
    teaserList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
        desc: PropTypes.string,
        media: PropTypes.object,
    })),
}

export default Teaser
