import React from 'react'
import PropTypes from 'prop-types'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import FrontasticImage from '../../atoms/frontasticImage'
import Button from '../../atoms/button/Button'

const Teaser = ({
    btnLinkText, btnVariant, btnColorDefault, btnColorHover, content: {
        media, name, reference, desc,
    },
}) => (
    <Reference
        reference={reference}
    >
        <div className={'teaser-block'}>
            {media && (
                <div className={'teaser-block--image'}>
                    <FrontasticImage
                        media={media}
                        title={name}
                        height={400}
                        width={400}
                        style={{
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </div>
            )}
            {name && <h5 className={'teaser-block--name'}>{name}</h5>}
            {desc && <p className={'teaser-block--desc'}>{desc}</p>}
            {btnLinkText
            && (
                <Button
                    className={`btn btn-${btnVariant} teaser-block--button btn--color-${btnColorDefault} btn--hover-${btnColorHover}`}
                >
                    {btnLinkText}
                </Button>
            )}
        </div>
    </Reference>
)

Teaser.propTypes = {
    btnLinkText: PropTypes.string,
    btnVariant: PropTypes.string,
    btnColorDefault: PropTypes.string,
    btnColorHover: PropTypes.string,
    content: PropTypes.shape({
        name: PropTypes.string,
        reference: PropTypes.object,
        desc: PropTypes.string,
        media: PropTypes.object,
    }),
}

export default Teaser
